import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import 'styles/reply-post.scss';

const Meta = () => {
  return (
    <Helmet>
      <title>tap to reply</title>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, minimum-scale=1"
      />
      <link
        rel="icon"
        type="image/png"
        href={'/whispr-favicon.webp'}
        sizes="16x16"
      />
      <meta charset="UTF-8" />
      <script
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6288985965837099"
        crossorigin="anonymous"
      ></script>
    </Helmet>
  );
};

const BottomContent = ({ showMakeQuestion }) => {
  return (
    <div className="bttom-content">
      {showMakeQuestion ? (
        <div className="make-question">
          <a>Make Your Own Question</a>
        </div>
      ) : null}
      <div className="swipr-ad">
        <a>
          <img src="/whispr-reply-post/swipr-ads.webp" />
        </a>
      </div>
    </div>
  );
};

const Tooltip = () => {
  return (
    <div className="tooltip">
      Posted from <strong>whispr</strong>{' '}
      <img width={18} height={18} src="/whispr-reply-post/whispr-logo-s.webp" />
    </div>
  );
};

const Send = ({ text, setReplied }) => {
  const [postDelayOption, setPostDelayOption] = useState(0);
  const onSend = () => {
    setReplied(true);
  };
  return (
    <div className="send-reply">
      <div
        className={`post-delay-option ${postDelayOption > 0 ? 'active' : ''}`}
      >
        <a
          href="javascript:void(0)"
          onClick={() => {
            setPostDelayOption((value) => {
              const newValue = value + 1;
              return newValue > 3 ? 0 : newValue;
            });
          }}
        >
          {postDelayOption === 0 ? (
            <img width={20} height={20} src="/whispr-reply-post/timer.webp" />
          ) : postDelayOption === 1 ? (
            '5m'
          ) : postDelayOption === 2 ? (
            '15m'
          ) : (
            '30m'
          )}
        </a>
      </div>
      <div className="button" onClick={onSend}>
        <a href="javascript:void(0)">{`${
          postDelayOption === 0 ? 'Send Now' : 'Send'
        }`}</a>
      </div>
      {postDelayOption > 0 ? (
        <div className="hint">
          Send after{' '}
          {postDelayOption === 1
            ? '5 miuntes'
            : postDelayOption === 2
            ? '15 minutes'
            : '30 minutes'}
        </div>
      ) : null}
    </div>
  );
};

const Replying = ({ setReplied }) => {
  const maxLength = 100;
  const [text, setText] = useState('');
  const [showBottomContent, setShowBottomConten] = useState(true);
  const onRandomText = () => {};

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const { subscribe, isSupported } = require('on-screen-keyboard-detector');
      if (isSupported()) {
        subscribe((visibility) => {
          if (visibility === 'hidden') {
            setShowBottomConten(true);
          } else {
            setShowBottomConten(false);
          }
        });
      }
    }
  }, []);

  return (
    <>
      <div className="background"></div>
      <div className="target-user-post">
        <div className="content">
          <div className="bitmoji">
            <img src="/whispr-reply-post/bitmoji.webp" />
          </div>
          <div className="text">
            <p>What would you do with 10 million $?</p>
          </div>
        </div>
        <div className="reply">
          <textarea
            onFocus={() => setShowBottomConten(false)}
            maxLength={maxLength}
            onChange={(e) => setText(e.target.value)}
            placeholder="Type a message..."
          />
          <img
            onClick={onRandomText}
            className="dice"
            width={42}
            height={42}
            src="/whispr-reply-post/dice.webp"
          />
          {text.length > maxLength - 10 ? (
            <div className="remaining-count">{maxLength - text.length}</div>
          ) : null}
        </div>
        {text.length > 0 ? (
          <Send setReplied={setReplied} text={text} />
        ) : (
          <Tooltip />
        )}
      </div>
      {showBottomContent ? <BottomContent showMakeQuestion={true} /> : null}
    </>
  );
};

const Replied = () => {
  return (
    <>
      <div className="background"></div>
      <div className="message-replied">
        <img
          width={64}
          height={64}
          src="/whispr-reply-post/whispr-logo-l.webp"
        />
        <p>Message Sent ️🎉</p>
        <div className="make-question">
          <a>Make Your Own Question</a>
        </div>
      </div>
      <BottomContent />
    </>
  );
};

const ReplyPost = () => {
  const [replied, setReplied] = useState(false);
  return (
    <>
      <Meta />
      {replied ? <Replied /> : <Replying setReplied={setReplied} />}
    </>
  );
};

export default ReplyPost;
